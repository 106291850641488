import * as React from "react";
import {
  Box,
  Typography,
  Button,
  Grid,
  Card,
  CardHeader,
  Divider,
} from "@mui/material";
import { useTranslation } from "react-i18next"; // Functional Components
import { FormatHelper } from "../../helper/FormatHelper";

export default function OrderSummaryCard({ data }) {
  const { t } = useTranslation();

  return (
    <Card variant="outlined">
      <CardHeader title={t(`dashboard.${data.rangeCode}`)} />
      <Divider />

      <Box sx={{ p: 2 }}>
        <Grid container spacing={2} sx={{ textAlign: "center" }}>
          <Grid item xs={6}>
            <Typography variant="h5">{data.result.cnt}</Typography>
            <Typography variant="caption">
              {t("dashboard.order_count")}
            </Typography>
          </Grid>

          <Grid item xs={6}>
            <Typography variant="h5">
              {FormatHelper.formatAmount(data.result.total)}
            </Typography>
            <Typography variant="caption">
              {t("dashboard.order_total")}
            </Typography>
          </Grid>
        </Grid>
      </Box>
      <Box textAlign={"center"} pb={2}>
        <Typography variant="body2">
          {`${data.dateRange.map((item) => item).join(" ~ ")}`}
        </Typography>
      </Box>
    </Card>
  );
}

import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import { Box, ButtonBase, Card, Grid2 as Grid, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { checkPermission } from "../../helper/PermissionHelper";
import { user } from "../../reducers/userSlice";

// icon
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ListIcon from "@mui/icons-material/List";
import PaymentIcon from "@mui/icons-material/Payment";
import StyleIcon from "@mui/icons-material/Style";

import {
  getPaymentMethodPageName,
  getProductOptionPageName,
  getProductOptionValuePageName,
  getRolePageName,
  getUserPageName,
} from "../../helper/GlobalValueHelper";

export default function List() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [list, setList] = useState([]);
  const currentUser = useSelector(user);

  useEffect(() => {
    setList([
      {
        icon: <PeopleAltIcon color="primary" sx={{ fontSize: 40 }} />,
        name: t("menu.users"),
        url: "/users",
        havePermission: checkPermission(currentUser, getUserPageName(), "view"),
      },
      {
        icon: <AdminPanelSettingsIcon color="primary" sx={{ fontSize: 40 }} />,
        name: t("menu.role"),
        url: "/role",
        havePermission: checkPermission(currentUser, getRolePageName(), "view"),
      },
      {
        icon: <StyleIcon color="primary" sx={{ fontSize: 40 }} />,
        name: t("setting.option_values"),
        url: "/products/options/values",
        havePermission: checkPermission(currentUser, getProductOptionValuePageName(), "view"),
      },
      {
        icon: <ListIcon color="primary" sx={{ fontSize: 40 }} />,
        name: t("setting.product_options"),
        url: "/products/options",
        havePermission: checkPermission(currentUser, getProductOptionPageName(), "view"),
      },
      {
        icon: <PaymentIcon color="primary" sx={{ fontSize: 40 }} />,
        name: t("setting.payment_method"),
        url: "/payment-method",
        havePermission: checkPermission(currentUser, getPaymentMethodPageName(), "view"),
      },
    ]);
  }, [currentUser]);

  const goToPage = url => {
    navigate(url);
  };

  return (
    <Box>
      <Typography variant="h6">{t("menu.settings")}</Typography>

      <Grid container sx={{ mt: 2 }} spacing={2}>
        {list.map((item, index) => (
          <Grid key={index} size={{
            xs: 12,
            md: 6,
          }}>
            {item.havePermission && (
              <ButtonBase
                sx={{
                  width: "100%",
                }}
              >
                <Card
                  variant="outlined"
                  onClick={() => goToPage(item.url)}
                  sx={{ p: 3, width: "100%" }}
                >
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    {item.icon}
                    <Box sx={{ flex: 1, textAlign: "left" }}>
                      <Typography variant="h6" sx={{ pl: 2 }}>
                        {item.name}
                      </Typography>
                      <Typography variant="subtitle2" sx={{ pl: 2 }}>
                        {item.description}
                      </Typography>
                    </Box>
                    <ArrowForwardIosIcon />
                  </Box>
                </Card>
              </ButtonBase>
            )}
          </Grid>
        ))}
      </Grid>
    </Box>
  );
}

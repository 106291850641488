import {
  Box,
  Grid2 as Grid
} from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next"; // Functional Components
import { useLocation } from "react-router-dom";
import { DashboardApi } from "../api/DashboardApi";
import DasgboardSkeleton from "../components/dashboard/DasgboardSkeleton";
import OrderSummaryCard from "../components/dashboard/OrderSummaryCard";

export default function Dashboard() {
  const { t } = useTranslation();
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const location = useLocation();

  useEffect(() => {
    getSummary();
  }, []);

  const getSummary = async () => {
    try {
      setIsLoading(true);
      const response = await DashboardApi.getSummary();
      setData(response);
      setIsLoading(false);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  if (isLoading) return <DasgboardSkeleton />;

  return (
    <Box sx={{ display: "flex" }}>
      <Grid container spacing={2}>
        {data.map((item, index) => (
          <Grid
            size={{
              xs: 12,
              md: item.type === "order_total_trend" ? 12 : 6,
            }}
            key={index}
          >
            {item.type === "order" && <OrderSummaryCard data={item} />}
            {/* {item.type === "member" && <MemberSummaryCard data={item} />} */}
          </Grid>
        ))}
      </Grid>
    </Box>
  );
}

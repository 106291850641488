import {
  Avatar,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Chip,
  Container,
  IconButton,
  Pagination,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";

// helper
import { checkPermission } from "../../helper/PermissionHelper";
import { getPlaceHolderImage } from "../../helper/UnitsHelper";
import { pageName } from "../../reducers/commonSlice";
import { user } from "../../reducers/userSlice";

// icons
import AddIcon from "@mui/icons-material/Add";
import SearchIcon from "@mui/icons-material/Search";
import PrintIcon from "@mui/icons-material/Print";

// components
import DebugLogger from "../../components/DebugLogger";
import FilterButton from "../../components/product/FilterButton";
import TableSkeleton from "../../components/TableSkeleton";

// api
import { ProductApi } from "../../api/ProductApi";
import { StoreApi } from "../../api/StoreApi";
import { CategoryApi } from "../../api/CategoryApi";

export default function List() {
  const { t } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();
  const [page, setPage] = useState(parseInt(searchParams.get("page")) || 1);
  const [pageSize, setPageSize] = useState(10);
  const [filter, setFilter] = useState([]);
  const [searchStr, setSearchStr] = useState();
  const [total, setTotal] = useState(0);
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();
  const [haveCreatePermission, setHaveCreatePermission] = useState(false);
  const [haveUpdatePermission, setHaveUpdatePermission] = useState(false);
  const currentUser = useSelector(user);
  const currentPage = useSelector(pageName);
  const [colSpan, setColSpan] = useState(7);
  const [storeList, setStoreList] = useState([]);
  const [categoryList, setCategoryList] = useState([]);

  const isApp = searchParams.get("isApp") || false;

  useEffect(() => {
    const createPermission = checkPermission(currentUser, currentPage, "create");
    setHaveCreatePermission(createPermission);

    const updatePermission = checkPermission(currentUser, currentPage, "update");
    setHaveUpdatePermission(updatePermission);
  }, [currentUser, currentPage]);

  useEffect(() => {
    fetchData();
    fetchStoreList();
    fetchCategoryList();
  }, [page]);

  useEffect(() => {
    if (!filter || filter.length <= 0) return;
    setPage(1);
    fetchData();
  }, [filter]);

  const fetchData = async () => {
    try {
      setIsLoading(true);
      const response = await ProductApi.list(page, pageSize, filter);
      setData(response.data);
      setTotal(response.total);
      setIsLoading(false);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchStoreList = async () => {
    try {
      const response = await StoreApi.list(1, -1, []);

      if (response.data) {
        setStoreList(response.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const fetchCategoryList = async () => {
    try {
      const response = await CategoryApi.list(1, -1, []);

      if (response.data) {
        setCategoryList(response.data);
      }
    } catch (error) {
      console.log(error);
    }
  };


  const viewDetail = id => {
    if (isApp) {
      navigate(`/products/${id}?isApp=${isApp}`);
    } else {
      navigate(`/products/${id}`);
    }
  };

  const create = () => {
    if (isApp) {
      navigate(`/products/create?isApp=${isApp}`);
    } else {
      navigate(`/products/create`);
    }
  };

  const onChangePage = value => {
    setPage(value);
    if (isApp) {
      setSearchParams({ page: value, isApp: isApp });
    } else {
      setSearchParams({ page: value });
    }
  };

  const onChangeSearchField = value => {
    setSearchStr(value);
  };

  const search = () => {
    setPage(1);
    let newFilter = filter.filter(item => item.field != "title");
    newFilter.unshift({ field: "title", value: searchStr });
    setFilter(newFilter);
  };

  const filterLabel = () => {
    let label = [];

    filter.forEach(item => {
      if (!item.value) return;

      if (item.field == "title") {
        label.push(`${t("layout.name")}: ${item.value}`);
      }

      if (item.field == "categories") {
        label.push(`${t("category.category")}: ${categoryList.find(x => x.id == item.value).name || ""}`);
      }

      if (item.field == "is_available") {
        let lable = "";

        if (item.value == "true") {
          lable = t("product.available");
        }
        console.log(item);

        if (item.value == "false") {
          lable = t("product.unavailable");
        }

        label.push(`${t("layout.status")}: ${lable}`);
      }

      if (item.field == "inventories") {
        label.push(`${t("menu.stores")}: ${storeList.find(x => x.id == item.id).name || ""}`);
      }

    });

    return label.join(", ");
  };

  const checkFilter = () => {
    let show = false;
    filter.forEach(item => {
      if (item.value) show = true;
    });

    return show;
  };

  return (
    <Container maxWidth="false">
      <Card variant="outlined">
        <CardHeader
          title={t("menu.products")}
          action={
            <Box display={"flex"} gap={1}>
              {haveCreatePermission && (
                <>
                  <Button
                    color="inherit"
                    size="small"
                    variant="outlined"
                    startIcon={<PrintIcon />}
                    onClick={() => {
                      navigate(`/products/barcode`);
                    }}
                  >
                    {t("product.generate_barcode")}
                  </Button>
                  <Button
                    color="inherit"
                    variant="outlined"
                    startIcon={<AddIcon />}
                    disabled={!haveCreatePermission}
                    onClick={create}
                  >
                    {t("product.new_product")}
                  </Button>
                </>
              )}
            </Box>
          }
        />
        <CardContent>
          <Box
            sx={{
              display: "flex",
              justifyContent: "end",
              alignItems: "center",
              pb: 1,
            }}
          >
            <Box>{checkFilter() && <Chip label={filterLabel()} />}</Box>
            <FilterButton searchStr={searchStr} setFilter={setFilter} storeList={storeList} categoryList={categoryList} />
            <TextField
              variant="outlined"
              placeholder={t("layout.search_by_title")}
              onChange={event => {
                onChangeSearchField(event.target.value);
              }}
              onKeyDown={event => {
                if (event.key == "Enter") {
                  search();
                }
              }}
              InputProps={{
                endAdornment: (
                  <IconButton
                    edge="end"
                    onClick={() => {
                      search();
                    }}
                  >
                    <SearchIcon />
                  </IconButton>
                ),
              }}
            />
          </Box>
          <TableContainer elevation={0} component={Paper}>
            <DebugLogger title="Products" data={data} hidden={true}></DebugLogger>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell width={"5%"}>{t("layout.id")}</TableCell>
                  <TableCell width={"5%"}>{t("layout.cover_image")}</TableCell>
                  <TableCell width={"10%"}>{t("layout.name")}</TableCell>
                  <TableCell width={"5%"}>{t("menu.suppliers")}</TableCell>
                  <TableCell width={"5%"}>{t("layout.status")}</TableCell>
                  <TableCell width={"10%"}>{t("layout.updated_by")}</TableCell>
                  <TableCell width={"10%"}>{t("layout.updated_at")}</TableCell>
                </TableRow>
              </TableHead>
              {isLoading ? (
                <TableSkeleton row={pageSize} colSpan={colSpan} />
              ) : (
                <TableBody>
                  {!data.length && (
                    <TableRow>
                      <TableCell colSpan={colSpan} align="center">
                        {t("layout.no_data")}
                      </TableCell>
                    </TableRow>
                  )}
                  {data.map((row, index) => (
                    <TableRow
                      hover
                      key={row.id}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                      onClick={() =>
                        haveUpdatePermission
                          ? viewDetail(row.id)
                          : toast.error(t("error.no_update_permission"))
                      }
                    >
                      <TableCell component="th" scope="row">
                        {row.id}
                      </TableCell>
                      <TableCell>
                        <Stack direction="row" spacing={2}>
                          <Avatar
                            variant="rounded"
                            sx={{ border: "1px solid #0000001f" }}
                            alt={`product_cover_image_${index}`}
                            src={row.images[0] || getPlaceHolderImage()}
                          />
                        </Stack>
                      </TableCell>
                      <TableCell>{row.title}</TableCell>
                      <TableCell>{row.supplier?.name || ""}</TableCell>
                      <TableCell>
                        <Chip
                          variant="outlined"
                          color={row.isAvailable ? "success" : "error"}
                          label={
                            row.isAvailable ? t("product.available") : t("product.unavailable")
                          }
                        />
                      </TableCell>
                      <TableCell>{row.updatedBy}</TableCell>
                      <TableCell>{row.updatedAt}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              )}
            </Table>
          </TableContainer>
        </CardContent>

        <CardActions sx={{ justifyContent: "space-between", px: 2 }}>
          <Typography variant="body2">
            {t("layout.total")}: {total}
          </Typography>
          <Stack spacing={2}>
            <Pagination
              page={page}
              count={Math.ceil(total / pageSize)}
              shape="rounded"
              onChange={(event, value) => {
                onChangePage(value);
              }}
            />
          </Stack>
        </CardActions>
      </Card>
    </Container>
  );
}
